import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import $api from '../../Service/api/intercepter';
import { setNotification } from '../../store/slices/notification';
import { logout } from '../../utils/auth';
import Loader from '../Loader';
import styles from './notification.module.scss';

interface INotification {
  isOpen: boolean;
  message: string;
  close: () => void;
}
const Notification: React.FC<INotification> = ({ isOpen, message, close }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const handleClose = () => {
    close();
    setIsLoading(false);
  };
  const handleConfirm = () => {
    setIsLoading(true);
    deleteUser();
  };

  const deleteUser = async () => {
    try {
      const res = await $api.delete('/profile/delete-user');
      if (res.status === 200) {
        setIsLoading(false);
        dispatch(setNotification({ message: 'Successfully deleted', type: 'success' }));
        logout(dispatch);
      }
    } catch (error) {
      close();
      setIsLoading(false);
      dispatch(setNotification({ message: 'Something went wrong', type: 'error' }));
    }
  };

  return (
    <>
      {isOpen && (
        <div className={`${styles.notification} ${styles['error']}`}>
          <p className={styles.notificationText}>{message}</p>
          {isLoading ? (
            <Loader color={'black'} />
          ) : (
            <div className={styles.notificationRow}>
              <button className={styles.notificationConfirm} onClick={handleConfirm}>
                Yes
              </button>
              <button className={styles.notificationClose} onClick={handleClose}>
                No
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Notification;

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import IconInfo from '../../../assets/imgComponent/IconInfo';
import { useStatistics } from '../../../hooks/useStatistics';
import { setModal, setModalType } from '../../../store/slices/modal';
import { MODAL_TYPES } from '../../../types/modals';
import { IStatisticsItem } from '../../../types/study';
import Confirmation from '../../Confirmation';
import styles from './profile.module.scss';

const UserStatistics: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { statistics, points } = useStatistics();
  const dispatch = useDispatch();

  const handleInfoClick = (modalType: string) => {
    dispatch(setModal(true));
    dispatch(setModalType(modalType));
  };

  const openConfirmation = () => {
    setIsOpen(true);
  };

  return (
    <section className={styles.profileStatistic}>
      <Confirmation
        isOpen={isOpen}
        close={() => setIsOpen(false)}
        message="Are you sure you want to delete your account? This action can not be undone"
      />
      <h2 className={styles.statisticsText}>
        Statistics
        <div
          className={styles.infoIcon}
          onClick={() => {
            handleInfoClick(MODAL_TYPES.statisticsInfo);
          }}
        >
          <IconInfo />
        </div>
      </h2>
      <ul className={styles.profileStatisticList}>
        {statistics?.map((item: IStatisticsItem) => (
          <li key={item.text}>
            <img src={item.src} alt={'info'} />
            <span>{item.text}</span>
            <strong>{item.count}</strong>
          </li>
        ))}
      </ul>
      <div className={styles.profileStatisticCount}>
        <span>
          Total dedication points: {points}
          <div
            className={styles.infoIcon}
            onClick={() => {
              handleInfoClick(MODAL_TYPES.pointsInfo);
            }}
          >
            <IconInfo />
          </div>
        </span>
        <button onClick={openConfirmation}>Delete account</button>
      </div>
    </section>
  );
};

export default UserStatistics;
